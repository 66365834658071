import React from 'react';
import './App.css';
import { Home } from './pages/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const Lunch = React.lazy(() => import('./pages/Lunch/Lunch'));
const NewYears = React.lazy(() => import('./pages/NewYears/NewYears'));
const NotFound = React.lazy(() => import('./pages/NotFound/NotFound'));
const Brunch = React.lazy(() => import('./pages/Brunch/Brunch'));

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/lunch" element={<React.Suspense fallback={<div>Loading...</div>}><Lunch /></React.Suspense>} />
                    <Route path="/brunch" element={<React.Suspense fallback={<div>Loading...</div>}><Brunch /></React.Suspense>} />
                    <Route path="/nyar" element={<React.Suspense fallback={<div>Loading...</div>}><NewYears /></React.Suspense>} />
                    <Route path="*" element={<React.Suspense fallback={<div>Loading...</div>}><NotFound /></React.Suspense>} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
