// hooks/useHomePageContent.ts
import { useEffect, useState } from 'react';
import axios from 'axios';

export interface OpeningHours {
    id: number;
    day_interval: string;
    hours: string;
}

export interface HomePageContent {
    book_table_cta: string;
    menu_text: string;
    lunch_link_text: string;
    open_hours_heading: string;
    opening_hours: OpeningHours[];
    menu_url?: string;
    createdAt: Date;
    publishedAt: Date;
    updatedAt: Date;
}

export const useHomePageContent = () => {
    const [homePageContent, setHomePageContent] = useState<HomePageContent | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const fetchHomePageContent = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_STRAPI_URL}/api/home-page`,
                    {
                        params: {
                            populate: 'opening_hours, contact_info, menu',
                        },
                    }
                );

                const menu_url: string =
                    process.env.REACT_APP_STRAPI_URL +
                    response.data.data.attributes.menu.data.attributes.url;

                const homePageContent: HomePageContent = {
                    book_table_cta: response.data.data.attributes.book_table_cta,
                    menu_text: response.data.data.attributes.menu_text,
                    open_hours_heading: response.data.data.attributes.open_hours_heading,
                    opening_hours: response.data.data.attributes.opening_hours,
                    menu_url: menu_url,
                    lunch_link_text: response.data.data.attributes.lunch_link_text,
                    createdAt: response.data.data.attributes.createdAt,
                    publishedAt: response.data.data.attributes.publishedAt,
                    updatedAt: response.data.data.attributes.updatedAt,
                };
                setHomePageContent(homePageContent);
            } catch (error) {
                console.error('Error fetching home page content:', error);
                setError(error as Error);
            } finally {
                setLoading(false);
            }
        };

        fetchHomePageContent();
    }, []);

    return { homePageContent, loading, error };
};
